//
// Main Javascript file
// Author: Sebastian Adel (http://www.freshmonday.ro)
//

/**
 *  Global Vars
 */
// Global
// var $viewport                    = $('html, body');
// var $window                      = $(window);
// var windowHeight                 = $window.height();
// var windowHeightPadded           = windowHeight / 1.2;
// var $main                        = $('.main');
// var mainStateClass               = 'has-floating';

// Textarea
// var $textarea                    = $('.js-textarea');

// Header
var headerStateNavClass          = 'has-open-menu';
var $header                      = $('.js-header');

// Nav
var $nav                         = $('.js-nav');
var $navAction                   = $('.js-nav-action');
var navStateClass                = 'is-visible';
var navStateClass                = 'is-visible';

// Contact
var $contact                     = $('.js-contact');
var $contactContent              = $('.js-contact-content');
var $contactNotice               = $('.js-contact-notice');

/**
 * [adara Object]
 * @type {Object}
 */
var adara = {

  init: function() {

    // Links actions
    adara.linksHandler();

    // Detect browsers
    adara.detectBrowser();

    // Navigation handler
    adara.navHandler();

    // Homepage slider
    adara.homepageSlider();

    // Homepage product slider
    adara.homepageProductSlider();

    // Contact handler
    adara.contactHandler();

    // Smooth Scroll
    adara.smoothScrollHandler();
  },

  // Links handler
  linksHandler: function() {
    // Initialize function
    function __init () {
      // Open in new window links with rel=external code
      $('a[rel="external"]').attr('target','_blank');
      // Prevent default action on # (hash) links
      $('a[href="#"]').on('click', function(e) { e.preventDefault(); });
    }

    // Initialize module
    return __init();
  },

  // Detect browsers
  detectBrowser: function() {
    // Initialize function
    function __init() {
      isIE = detectIE();
      // Add class to HTML element
      if (isIE) { $('html').addClass('ie '+isIE); }
    }

    // Detect IE
    function detectIE() {
      var ua      = window.navigator.userAgent;
      var msie    = ua.indexOf('MSIE ');
      var trident = ua.indexOf('Trident/');

      if (msie > 0) {
        // IE 10 or older => return version number
        return 'ie'+parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
      }

      if (trident > 0) {
        // IE 11 (or newer) => return version number
        var rv = ua.indexOf('rv:');
        return 'ie'+parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
      }
      // Other browser
      return false;
    }

    // Initialize module
    return __init();
  },

  // Navigation handler
  navHandler: function(action) {
      // Set variable to false if not defined
      action = action || null;

      // Initialize function
      function __init() {
          switch (action) {
              case 'navActions':
                  // Nav actions
                  navActions();
              break;
              case 'navClose':
                  // Nav close
                  navClose();
              break;
              case 'navOpen':
                  // Nav close
                  navOpen();
              break;
              default:
              // Nav actions
              navActions();
          }
      }
      // Nav actions
      function navActions() {
          $navAction.unbind('click').on('click', function(e) {
              e.preventDefault();
              if ($nav.length > 0) {
                  if ($header.hasClass(headerStateNavClass)) {
                      navClose();
                  } else {
                      navOpen();
                  }
              }
          });
      }
      // Nav open
      function navOpen() {
              $nav.addClass(navStateClass);
              $header.addClass(headerStateNavClass);
      }
      // Nav close
      function navClose() {
              $nav.removeClass(navStateClass);
              $header.removeClass(headerStateNavClass);
      }

      // Initialize module
      return __init();
  },

  // Contact handler
  contactHandler: function() {
      // Initialize function
      function __init() {
          if ($contact.length > 0) {
              // Vars
              var contactHeight = $contact.outerHeight();

              // Validate contact form
              $contact.h5Validate();

              // Process contact form
              $contact.submit(function(e) {
                  // Vars
                  var result = $contact.h5Validate('allValid');
                  var data;
                  var url;

                  if ( result === true ) {
                      // Serialize contact data
                      data = $(this).serialize();
                      // Get URL from action
                      url = $(this).attr('action');
                      method = $(this).attr('method');

                      // Send request
                      $.ajax({
                          url         : url,
                          data        : data,
                          type        : method,
                          cache       : false,
                          error       : function(result) {
                              // Log message
                              console.log(result);
                          },
                          success     : function(result) {
                              result = result || null;
                              // Log message
                              // console.log(result);
                              // Prevent resizing
                              $contact.css({'min-height': contactHeight + 'px'});
                              // Hide form content for 10s
                              $contactContent.velocity("fadeOut", { duration: 800 })
                                             .velocity("fadeIn", { delay: 10000, duration: 1000 });
                              // Show form notice for 9s
                              $contactNotice.velocity("fadeIn", { delay: 850, duration: 1500 })
                                             .velocity("fadeOut", { delay: 7000, duration: 800 });
                              // Clear fields
                              $contact.trigger('reset');
                          }
                      });
                  }
                  // Prevent actual form submit
                  e.preventDefault();
              });
          }
      }

      // Initialize module
      return __init();
  },

  // HomepageSlider
  homepageSlider: function() {
    $('.js-homepage-slideshow').slick({
      dots: true,
      arrows: false,
      infinite: true,
      autoplay: true,
      cssEase: 'linear'
    });
  },

  // HomepageProductSlider
  homepageProductSlider: function() {
    $('.js-homepage-product-slider').slick({
      dots: true,
      infinite: true,
      speed: 300,
      slidesToShow: 4,
      slidesToScroll: 4,

      responsive: [
        {
          breakpoint: 1100,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            arrows: false
          }
        },
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          }
        },
        {
        breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          }
        }
      ]
    });
  },

  // Smooth Scroll Handler
  smoothScrollHandler: function() {
    $(".js-smooth-scroll").click(function(e) {
      e.preventDefault();

      var target = $(this).attr("href");
      var speed = 2500;

      if (!target) {
        target = $(this).find("a").attr("href");
      }

      anchorScroll($(target), speed );
    });

    function anchorScroll(target_obj, speed) {
      var target_offset = target_obj.offset();

      $("html,body").animate({
        scrollTop: target_offset.top
      }, {
        duration: speed,
        easing: "easeInOutQuint"
      });
    }
  }
};

/**
 * Document ready (loaded)
 */
jQuery(document).ready(function() {
  // Init scripts
  adara.init();
});

/**
 *  Document load (in process of loading)
 */
jQuery(window).load(function() {
  // Do stuff
});
